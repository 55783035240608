import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import logo from "./logo.svg"
import AdShow from "./ad"
import gift from "./gift.png"
import city from "./city.webp"
import { init } from 'ityped'
import Toast from "./toast"
import web1 from "./web.png"
import axios from 'axios';
import CdShow from './cd';

function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])

	


	
	useEffect(() => {
		let count = parseInt(localStorage.getItem('visitCount')) || 22;
		// Increment the count by 1 or 2
		count += Math.floor(Math.random() * 2) + 1;
		// Cap the count at 50
		count = Math.min(count, 49);
		// Save the updated count to local storage
		localStorage.setItem('visitCount', count.toString());
	}, [])


	
	const [uIp, setUIp] = useState("")
	useEffect(async () => {
		const res = await axios.get("https://api.ipify.org?format=json")
		setUIp(res.data.ip)
	}, [])

	
	
	const ref1 = useRef("")

	useEffect(() => {
		function animateValue(obj, start, end, duration) {
			let startTimestamp = null;
			const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min((timestamp - startTimestamp) / duration, 1);
			obj.innerHTML = Math.floor(progress * (end - start) + start);
			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
			};
			window.requestAnimationFrame(step);
		}
		
		const obj = document.getElementById("cHolder");
		animateValue(obj, 0, parseInt(localStorage.getItem('visitCount')) || 22, 5000);
	}, [])

	const [loading, setLoading] = useState(false)
	

	const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]} color="#000">
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    )

	const mapTimezoneToCountry = {
		"Europe/Andorra": "Andorra",
		"Asia/Dubai": "United Arab Emirates",
		"Asia/Kabul": "Afghanistan",
		"Europe/Tirane": "Albania",
		"Asia/Yerevan": "Armenia",
		"Antarctica/Casey": "Antarctica",
		"Antarctica/Davis": "Antarctica",
		"Antarctica/Mawson": "Antarctica",
		"Antarctica/Palmer": "Antarctica",
		"Antarctica/Rothera": "Antarctica",
		"Antarctica/Troll": "Antarctica",
		"Antarctica/Vostok": "Antarctica",
		"America/Argentina/Buenos_Aires": "Argentina",
		"America/Argentina/Cordoba": "Argentina",
		"America/Argentina/Salta": "Argentina",
		"America/Argentina/Jujuy": "Argentina",
		"America/Argentina/Tucuman": "Argentina",
		"America/Argentina/Catamarca": "Argentina",
		"America/Argentina/La_Rioja": "Argentina",
		"America/Argentina/San_Juan": "Argentina",
		"America/Argentina/Mendoza": "Argentina",
		"America/Argentina/San_Luis": "Argentina",
		"America/Argentina/Rio_Gallegos": "Argentina",
		"America/Argentina/Ushuaia": "Argentina",
		"Pacific/Pago_Pago": "Samoa (American)",
		"Europe/Vienna": "Austria",
		"Australia/Lord_Howe": "Australia",
		"Antarctica/Macquarie": "Australia",
		"Australia/Hobart": "Australia",
		"Australia/Melbourne": "Australia",
		"Australia/Sydney": "Australia",
		"Australia/Broken_Hill": "Australia",
		"Australia/Brisbane": "Australia",
		"Australia/Lindeman": "Australia",
		"Australia/Adelaide": "Australia",
		"Australia/Darwin": "Australia",
		"Australia/Perth": "Australia",
		"Australia/Eucla": "Australia",
		"Asia/Baku": "Azerbaijan",
		"America/Barbados": "Barbados",
		"Asia/Dhaka": "Bangladesh",
		"Europe/Brussels": "Belgium",
		"Europe/Sofia": "Bulgaria",
		"Atlantic/Bermuda": "Bermuda",
		"Asia/Brunei": "Brunei",
		"America/La_Paz": "Bolivia",
		"America/Noronha": "Brazil",
		"America/Belem": "Brazil",
		"America/Fortaleza": "Brazil",
		"America/Recife": "Brazil",
		"America/Araguaina": "Brazil",
		"America/Maceio": "Brazil",
		"America/Bahia": "Brazil",
		"America/Sao_Paulo": "Brazil",
		"America/Campo_Grande": "Brazil",
		"America/Cuiaba": "Brazil",
		"America/Santarem": "Brazil",
		"America/Porto_Velho": "Brazil",
		"America/Boa_Vista": "Brazil",
		"America/Manaus": "Brazil",
		"America/Eirunepe": "Brazil",
		"America/Rio_Branco": "Brazil",
		"Asia/Thimphu": "Bhutan",
		"Europe/Minsk": "Belarus",
		"America/Belize": "Belize",
		"America/St_Johns": "Canada",
		"America/Halifax": "Canada",
		"America/Glace_Bay": "Canada",
		"America/Moncton": "Canada",
		"America/Goose_Bay": "Canada",
		"America/Toronto": "Canada",
		"America/Nipigon": "Canada",
		"America/Thunder_Bay": "Canada",
		"America/Iqaluit": "Canada",
		"America/Pangnirtung": "Canada",
		"America/Winnipeg": "Canada",
		"America/Rainy_River": "Canada",
		"America/Resolute": "Canada",
		"America/Rankin_Inlet": "Canada",
		"America/Regina": "Canada",
		"America/Swift_Current": "Canada",
		"America/Edmonton": "Canada",
		"America/Cambridge_Bay": "Canada",
		"America/Yellowknife": "Canada",
		"America/Inuvik": "Canada",
		"America/Dawson_Creek": "Canada",
		"America/Fort_Nelson": "Canada",
		"America/Whitehorse": "Canada",
		"America/Dawson": "Canada",
		"America/Vancouver": "Canada",
		"Indian/Cocos": "Cocos (Keeling) Islands",
		"Europe/Zurich": "Switzerland",
		"Africa/Abidjan": "Côte d'Ivoire",
		"Pacific/Rarotonga": "Cook Islands",
		"America/Santiago": "Chile",
		"America/Punta_Arenas": "Chile",
		"Pacific/Easter": "Chile",
		"Asia/Shanghai": "China",
		"Asia/Urumqi": "China",
		"America/Bogota": "Colombia",
		"America/Costa_Rica": "Costa Rica",
		"America/Havana": "Cuba",
		"Atlantic/Cape_Verde": "Cape Verde",
		"Indian/Christmas": "Christmas Island",
		"Asia/Nicosia": "Cyprus",
		"Asia/Famagusta": "Cyprus",
		"Europe/Prague": "Czech Republic",
		"Europe/Berlin": "Germany",
		"Europe/Copenhagen": "Denmark",
		"America/Santo_Domingo": "Dominican Republic",
		"Africa/Algiers": "Algeria",
		"America/Guayaquil": "Ecuador",
		"Pacific/Galapagos": "Ecuador",
		"Europe/Tallinn": "Estonia",
		"Africa/Cairo": "Egypt",
		"Africa/El_Aaiun": "Western Sahara",
		"Europe/Madrid": "Spain",
		"Africa/Ceuta": "Spain",
		"Atlantic/Canary": "Spain",
		"Europe/Helsinki": "Finland",
		"Pacific/Fiji": "Fiji",
		"Atlantic/Stanley": "Falkland Islands",
		"Pacific/Chuuk": "Micronesia",
		"Pacific/Pohnpei": "Micronesia",
		"Pacific/Kosrae": "Micronesia",
		"Atlantic/Faroe": "Faroe Islands",
		"Europe/Paris": "France",
		"Europe/London": "Britain (UK)",
		"Asia/Tbilisi": "Georgia",
		"America/Cayenne": "French Guiana",
		"Europe/Gibraltar": "Gibraltar",
		"America/Nuuk": "Greenland",
		"America/Danmarkshavn": "Greenland",
		"America/Scoresbysund": "Greenland",
		"America/Thule": "Greenland",
		"Europe/Athens": "Greece",
		"Atlantic/South_Georgia": "South Georgia & the South Sandwich Islands",
		"America/Guatemala": "Guatemala",
		"Pacific/Guam": "Guam",
		"Africa/Bissau": "Guinea-Bissau",
		"America/Guyana": "Guyana",
		"Asia/Hong_Kong": "Hong Kong",
		"America/Tegucigalpa": "Honduras",
		"America/Port-au-Prince": "Haiti",
		"Europe/Budapest": "Hungary",
		"Asia/Jakarta": "Indonesia",
		"Asia/Pontianak": "Indonesia",
		"Asia/Makassar": "Indonesia",
		"Asia/Jayapura": "Indonesia",
		"Europe/Dublin": "Ireland",
		"Asia/Jerusalem": "Israel",
		"Asia/Kolkata": "India",
		"Asia/Calcutta": "India",
		"Indian/Chagos": "British Indian Ocean Territory",
		"Asia/Baghdad": "Iraq",
		"Asia/Tehran": "Iran",
		"Atlantic/Reykjavik": "Iceland",
		"Europe/Rome": "Italy",
		"America/Jamaica": "Jamaica",
		"Asia/Amman": "Jordan",
		"Asia/Tokyo": "Japan",
		"Africa/Nairobi": "Kenya",
		"Asia/Bishkek": "Kyrgyzstan",
		"Pacific/Tarawa": "Kiribati",
		"Pacific/Kanton": "Kiribati",
		"Pacific/Kiritimati": "Kiribati",
		"Asia/Pyongyang": "Korea (North)",
		"Asia/Seoul": "Korea (South)",
		"Asia/Almaty": "Kazakhstan",
		"Asia/Qyzylorda": "Kazakhstan",
		"Asia/Qostanay": "Kazakhstan",
		"Asia/Aqtobe": "Kazakhstan",
		"Asia/Aqtau": "Kazakhstan",
		"Asia/Atyrau": "Kazakhstan",
		"Asia/Oral": "Kazakhstan",
		"Asia/Beirut": "Lebanon",
		"Asia/Colombo": "Sri Lanka",
		"Africa/Monrovia": "Liberia",
		"Europe/Vilnius": "Lithuania",
		"Europe/Luxembourg": "Luxembourg",
		"Europe/Riga": "Latvia",
		"Africa/Tripoli": "Libya",
		"Africa/Casablanca": "Morocco",
		"Europe/Monaco": "Monaco",
		"Europe/Chisinau": "Moldova",
		"Pacific/Majuro": "Marshall Islands",
		"Pacific/Kwajalein": "Marshall Islands",
		"Asia/Yangon": "Myanmar (Burma)",
		"Asia/Ulaanbaatar": "Mongolia",
		"Asia/Hovd": "Mongolia",
		"Asia/Choibalsan": "Mongolia",
		"Asia/Macau": "Macau",
		"America/Martinique": "Martinique",
		"Europe/Malta": "Malta",
		"Indian/Mauritius": "Mauritius",
		"Indian/Maldives": "Maldives",
		"America/Mexico_City": "Mexico",
		"America/Cancun": "Mexico",
		"America/Merida": "Mexico",
		"America/Monterrey": "Mexico",
		"America/Matamoros": "Mexico",
		"America/Mazatlan": "Mexico",
		"America/Chihuahua": "Mexico",
		"America/Ojinaga": "Mexico",
		"America/Hermosillo": "Mexico",
		"America/Tijuana": "Mexico",
		"America/Bahia_Banderas": "Mexico",
		"Asia/Kuala_Lumpur": "Malaysia",
		"Asia/Kuching": "Malaysia",
		"Africa/Maputo": "Mozambique",
		"Africa/Windhoek": "Namibia",
		"Pacific/Noumea": "New Caledonia",
		"Pacific/Norfolk": "Norfolk Island",
		"Africa/Lagos": "Nigeria",
		"America/Managua": "Nicaragua",
		"Europe/Amsterdam": "Netherlands",
		"Europe/Oslo": "Norway",
		"Asia/Kathmandu": "Nepal",
		"Pacific/Nauru": "Nauru",
		"Pacific/Niue": "Niue",
		"Pacific/Auckland": "New Zealand",
		"Pacific/Chatham": "New Zealand",
		"America/Panama": "Panama",
		"America/Lima": "Peru",
		"Pacific/Tahiti": "French Polynesia",
		"Pacific/Marquesas": "French Polynesia",
		"Pacific/Gambier": "French Polynesia",
		"Pacific/Port_Moresby": "Papua New Guinea",
		"Pacific/Bougainville": "Papua New Guinea",
		"Asia/Manila": "Philippines",
		"Asia/Karachi": "Pakistan",
		"Europe/Warsaw": "Poland",
		"America/Miquelon": "St Pierre & Miquelon",
		"Pacific/Pitcairn": "Pitcairn",
		"America/Puerto_Rico": "Puerto Rico",
		"Asia/Gaza": "Palestine",
		"Asia/Hebron": "Palestine",
		"Europe/Lisbon": "Portugal",
		"Atlantic/Madeira": "Portugal",
		"Atlantic/Azores": "Portugal",
		"Pacific/Palau": "Palau",
		"America/Asuncion": "Paraguay",
		"Asia/Qatar": "Qatar",
		"Indian/Reunion": "Réunion",
		"Europe/Bucharest": "Romania",
		"Europe/Belgrade": "Serbia",
		"Europe/Kaliningrad": "Russia",
		"Europe/Moscow": "Russia",
		"Europe/Simferopol": "Russia",
		"Europe/Kirov": "Russia",
		"Europe/Volgograd": "Russia",
		"Europe/Astrakhan": "Russia",
		"Europe/Saratov": "Russia",
		"Europe/Ulyanovsk": "Russia",
		"Europe/Samara": "Russia",
		"Asia/Yekaterinburg": "Russia",
		"Asia/Omsk": "Russia",
		"Asia/Novosibirsk": "Russia",
		"Asia/Barnaul": "Russia",
		"Asia/Tomsk": "Russia",
		"Asia/Novokuznetsk": "Russia",
		"Asia/Krasnoyarsk": "Russia",
		"Asia/Irkutsk": "Russia",
		"Asia/Chita": "Russia",
		"Asia/Yakutsk": "Russia",
		"Asia/Khandyga": "Russia",
		"Asia/Vladivostok": "Russia",
		"Asia/Ust-Nera": "Russia",
		"Asia/Magadan": "Russia",
		"Asia/Sakhalin": "Russia",
		"Asia/Srednekolymsk": "Russia",
		"Asia/Kamchatka": "Russia",
		"Asia/Anadyr": "Russia",
		"Asia/Riyadh": "Saudi Arabia",
		"Pacific/Guadalcanal": "Solomon Islands",
		"Indian/Mahe": "Seychelles",
		"Africa/Khartoum": "Sudan",
		"Europe/Stockholm": "Sweden",
		"Asia/Singapore": "Singapore",
		"America/Paramaribo": "Suriname",
		"Africa/Juba": "South Sudan",
		"Africa/Sao_Tome": "Sao Tome & Principe",
		"America/El_Salvador": "El Salvador",
		"Asia/Damascus": "Syria",
		"America/Grand_Turk": "Turks & Caicos Is",
		"Africa/Ndjamena": "Chad",
		"Indian/Kerguelen": "French Southern & Antarctic Lands",
		"Asia/Bangkok": "Thailand",
		"Asia/Dushanbe": "Tajikistan",
		"Pacific/Fakaofo": "Tokelau",
		"Asia/Dili": "East Timor",
		"Asia/Ashgabat": "Turkmenistan",
		"Africa/Tunis": "Tunisia",
		"Pacific/Tongatapu": "Tonga",
		"Europe/Istanbul": "Turkey",
		"Pacific/Funafuti": "Tuvalu",
		"Asia/Taipei": "Taiwan",
		"Europe/Kiev": "Ukraine",
		"Europe/Uzhgorod": "Ukraine",
		"Europe/Zaporozhye": "Ukraine",
		"Pacific/Wake": "US minor outlying islands",
		"America/New_York": "United States",
		"America/Detroit": "United States",
		"America/Kentucky/Louisville": "United States",
		"America/Kentucky/Monticello": "United States",
		"America/Indiana/Indianapolis": "United States",
		"America/Indiana/Vincennes": "United States",
		"America/Indiana/Winamac": "United States",
		"America/Indiana/Marengo": "United States",
		"America/Indiana/Petersburg": "United States",
		"America/Indiana/Vevay": "United States",
		"America/Chicago": "United States",
		"America/Indiana/Tell_City": "United States",
		"America/Indiana/Knox": "United States",
		"America/Menominee": "United States",
		"America/North_Dakota/Center": "United States",
		"America/North_Dakota/New_Salem": "United States",
		"America/North_Dakota/Beulah": "United States",
		"America/Denver": "United States",
		"America/Boise": "United States",
		"America/Phoenix": "United States",
		"America/Los_Angeles": "United States",
		"America/Anchorage": "United States",
		"America/Juneau": "United States",
		"America/Sitka": "United States",
		"America/Metlakatla": "United States",
		"America/Yakutat": "United States",
		"America/Nome": "United States",
		"America/Adak": "United States",
		"Pacific/Honolulu": "United States",
		"America/Montevideo": "Uruguay",
		"Asia/Samarkand": "Uzbekistan",
		"Asia/Tashkent": "Uzbekistan",
		"America/Caracas": "Venezuela",
		"Asia/Ho_Chi_Minh": "Vietnam",
		"Pacific/Efate": "Vanuatu",
		"Pacific/Wallis": "Wallis & Futuna",
		"Pacific/Apia": "Samoa (western)",
		"Africa/Johannesburg": "South Africa",
		"America/Antigua": "Antigua & Barbuda",
		"America/Anguilla": "Anguilla",
		"Africa/Luanda": "Angola",
		"Antarctica/McMurdo": "Antarctica",
		"Antarctica/DumontDUrville": "Antarctica",
		"Antarctica/Syowa": "Antarctica",
		"America/Aruba": "Aruba",
		"Europe/Mariehamn": "Åland Islands",
		"Europe/Sarajevo": "Bosnia & Herzegovina",
		"Africa/Ouagadougou": "Burkina Faso",
		"Asia/Bahrain": "Bahrain",
		"Africa/Bujumbura": "Burundi",
		"Africa/Porto-Novo": "Benin",
		"America/St_Barthelemy": "St Barthelemy",
		"America/Kralendijk": "Caribbean NL",
		"America/Nassau": "Bahamas",
		"Africa/Gaborone": "Botswana",
		"America/Blanc-Sablon": "Canada",
		"America/Atikokan": "Canada",
		"America/Creston": "Canada",
		"Africa/Kinshasa": "Congo (Dem. Rep.)",
		"Africa/Lubumbashi": "Congo (Dem. Rep.)",
		"Africa/Bangui": "Central African Rep.",
		"Africa/Brazzaville": "Congo (Rep.)",
		"Africa/Douala": "Cameroon",
		"America/Curacao": "Curaçao",
		"Europe/Busingen": "Germany",
		"Africa/Djibouti": "Djibouti",
		"America/Dominica": "Dominica",
		"Africa/Asmara": "Eritrea",
		"Africa/Addis_Ababa": "Ethiopia",
		"Africa/Libreville": "Gabon",
		"America/Grenada": "Grenada",
		"Europe/Guernsey": "Guernsey",
		"Africa/Accra": "Ghana",
		"Africa/Banjul": "Gambia",
		"Africa/Conakry": "Guinea",
		"America/Guadeloupe": "Guadeloupe",
		"Africa/Malabo": "Equatorial Guinea",
		"Europe/Zagreb": "Croatia",
		"Europe/Isle_of_Man": "Isle of Man",
		"Europe/Jersey": "Jersey",
		"Asia/Phnom_Penh": "Cambodia",
		"Indian/Comoro": "Comoros",
		"America/St_Kitts": "St Kitts & Nevis",
		"Asia/Kuwait": "Kuwait",
		"America/Cayman": "Cayman Islands",
		"Asia/Vientiane": "Laos",
		"America/St_Lucia": "St Lucia",
		"Europe/Vaduz": "Liechtenstein",
		"Africa/Maseru": "Lesotho",
		"Europe/Podgorica": "Montenegro",
		"America/Marigot": "St Martin (French)",
		"Indian/Antananarivo": "Madagascar",
		"Europe/Skopje": "North Macedonia",
		"Africa/Bamako": "Mali",
		"Pacific/Saipan": "Northern Mariana Islands",
		"Africa/Nouakchott": "Mauritania",
		"America/Montserrat": "Montserrat",
		"Africa/Blantyre": "Malawi",
		"Africa/Niamey": "Niger",
		"Asia/Muscat": "Oman",
		"Africa/Kigali": "Rwanda",
		"Atlantic/St_Helena": "St Helena",
		"Europe/Ljubljana": "Slovenia",
		"Arctic/Longyearbyen": "Svalbard & Jan Mayen",
		"Europe/Bratislava": "Slovakia",
		"Africa/Freetown": "Sierra Leone",
		"Europe/San_Marino": "San Marino",
		"Africa/Dakar": "Senegal",
		"Africa/Mogadishu": "Somalia",
		"America/Lower_Princes": "St Maarten (Dutch)",
		"Africa/Mbabane": "Eswatini (Swaziland)",
		"Africa/Lome": "Togo",
		"America/Port_of_Spain": "Trinidad & Tobago",
		"Africa/Dar_es_Salaam": "Tanzania",
		"Africa/Kampala": "Uganda",
		"Pacific/Midway": "US minor outlying islands",
		"Europe/Vatican": "Vatican City",
		"America/St_Vincent": "St Vincent",
		"America/Tortola": "Virgin Islands (UK)",
		"America/St_Thomas": "Virgin Islands (US)",
		"Asia/Aden": "Yemen",
		"Indian/Mayotte": "Mayotte",
		"Africa/Lusaka": "Zambia",
		"Africa/Harare": "Zimbabwe"
	}
	const [reCountr, setReCountr] = useState("Your Region")

	useEffect(() => {
		var userCountry;
		var userTimeZone;
		userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		userCountry = mapTimezoneToCountry[userTimeZone];
		if(userCountry !== undefined) {
			setReCountr(userCountry)
		}
	}, [])




	

	return (
		<ChakraProvider>
			<Flex w="100%" direction="column" fontSize="15px">
				<Flex w="100%" h="20px" bg="#ececec"></Flex>

				<Flex w="100%" align="center" py="8" px={["5%", "6%"]} position="sticky" top="0" bg="#fff" zIndex="100">
					<Flex flex='1'>
						<Image w={["109px", "158px"]} src={logo} />
					</Flex>
					<Text mr={["4", "6"]} fontSize="20px" cursor="pointer" color="#1DA1F2" onClick={() => window.open("https://twitter", "_BLANK")}><i className='mdi mdi-twitter'></i></Text>
					<Text fontSize={["13px", "15px"]} cursor="pointer" mr={["4", "6"]} onClick={() => window.open("https://discord", "_BLANK")}>Discord</Text>
					<Text fontSize={["13px", "15px"]} cursor="pointer" onClick={() => {
						const element = document.getElementById('fq')
						return window.scrollTo({
						top: element ? element.offsetTop - 50 : 0,
						behavior: 'smooth',
						})
					}}>FAQ</Text>
				</Flex>

				
				<Text borderTop="1px solid rgb(200,200,200)" mx={["5%", "6%"]}></Text>
				<Flex px={["5%", "10%"]} direction="column" py="10" >
					<Flex w="100%" justify="center" mb="5">
						<Flex w="120px" h="120px" justify="center" align="center" borderRadius="100%" border="1px solid #00b951"><Image w="80px" src={gift} className='bounce2' zIndex="10" /></Flex>
					</Flex>
					<Text textAlign="center" px={["5%", "0"]} fontSize="24px" fontWeight="bold" data-aos="fade-down">Launching a global initiative</Text>
					<Text textAlign="center" fontSize={["14px", "15px"]} color="rgb(150,150,150)" mt={["3", "2"]} px={["0%", "6%"]} data-aos="fade-up">We aim to reward <Text as="span" color="#00b951" fontWeight="bold">50</Text> individuals <Text as="span" color="#00b951" fontWeight="bold">per</Text> country with crypto tokens valued between <Text as="span" color="#00b951" fontWeight="bold">$1,000</Text> and <Text as="span" color="#00b951" fontWeight="bold">$5,000</Text> each. This groundbreaking endeavor seeks to foster widespread participation and engagement, empowering recipients with the potential of blockchain technology and decentralized finance.</Text>
				</Flex>
				
				<Flex w="100%" py={["80px", "100px"]} px={["10%", "10%"]} bgColor="rgb(1, 220, 148)" backgroundImage={city} backgroundRepeat="no-repeat" backgroundSize="cover" align="center" backgroundPosition="bottom center" direction={["column", "row"]}>
					<Flex flex="1" direction="column" color="#fff">
						<Image src={web1} w={["80px", "100px"]} mb={["8", "10"]} />
						<Text fontSize={["70px", "100px"]} lineHeight={["70px", "100px"]}><Text as="span" id="cHolder"></Text>/50</Text>
						<Text fontSize={["13px", "16px"]}>People has claimed reward from <Text as="span" fontWeight="bold" fontSize="20px">{reCountr}</Text>.</Text>
						<Text mt="2" fontSize={["18px", "20px"]}><Text as="span" mr="2"><i className='mdi mdi-web'></i></Text>{uIp}</Text>

						<CdShow />
					</Flex>
					<Flex flex="1" mt={["12", "0"]} direction="column">
						<Text fontSize={["70px", "100px"]} lineHeight={["70px", "100px"]}>Claim Reward</Text>

						<Flex cursor="pointer!important" bg="#dcfe54" mt="8" fontWeight="bold" mr={["0", "5%"]} justify="center" align="center" color="#000" borderRadius="30px" fontSize={["17px", "20px"]} padding={["18px", "18px 80px"]} className='bounce2 connectButton'>Connect Wallet <Text as="span" ml="4"><i className='mdi mdi-wallet'></i></Text></Flex>
					</Flex>
				</Flex>




				<Flex w="100%" px={["5%", "15%"]} py={["60px", "80px"]} direction="column" bg="#fff" id="fq">
					<Text fontSize="40px" textAlign="center" fontWeight="bold">FAQ</Text>
					<Text textAlign="center" fontSize={["13px", "15px"]} px={["0", "10%"]} color="rgb(150,150,150)"> Explore information on the global reach, inclusive country participation, and the flexibility of trading tokens. Additionally, discover how the initiative contributes to blockchain education and its anticipated impact on global adoption.</Text>
					<Flex mt="8" background="linear-gradient(45deg,rgba(255, 255, 255, 0) 4%,rgba(1, 220, 148, 1) 4%,rgba(1, 220, 148, 1) 96%,rgba(255, 255, 255, 0) 96%)" py={["6", "8"]} px={["6", "12"]} direction="column">
					<Accordion transition transitionTimeout={200}>
                        <AccordionItem header="What is the Algorand Foundation Global Crypto Reward Initiative?" initialEntered>
						Algorand Foundation Global Crypto Reward Initiative is a worldwide program designed to reward 50 individuals in each country with crypto tokens. The initiative aims to promote widespread adoption of blockchain technology and decentralized finance by providing participants with crypto assets valued between $1,000 and $5,000
                        </AccordionItem>

                        <AccordionItem header="Which countries are included in the initiative?">
						The initiative is global, covering participants from every country. The goal is to encourage inclusivity and empower individuals globally, fostering a diverse community of crypto enthusiasts and users.
                        </AccordionItem>

                        <AccordionItem header=" How will the initiative impact the adoption of blockchain and decentralized finance?">
						By distributing crypto tokens on a global scale, the initiative aims to introduce individuals to the benefits of blockchain and decentralized finance. It encourages participants to explore the potential of these technologies, fostering a broader understanding and adoption within communities worldwide.
                        </AccordionItem>
						<AccordionItem header="How will the value of the reward be determined?">
						The value of the reward will range between $1,000 and $5,000 worth of crypto tokens per participant. The exact amount may depend on various factors, including participation level, regional considerations, and the overall success of the initiative.
                        </AccordionItem>
                        <AccordionItem header="Can I transfer or trade the tokens I receive?">
						Yes, participants will have the flexibility to transfer or trade the tokens they receive. The tokens are designed to be fungible and compatible with various decentralized exchanges, providing users with the freedom to manage their assets as they see fit.
                        </AccordionItem>
                    </Accordion>
					</Flex>
				</Flex>

				
				
				<Text borderTop="1px solid rgb(200,200,200)" mx={["5%", "6%"]}></Text>



				<Flex py="60px" px={["5%", "6%"]} direction={["column", "row"]}>
					<Flex w={["100%", "30%"]} direction="column">
						<Image src={logo} w={["70px", "100px"]} />

						<Text color="rgb(150,150,150)" mt="5">Subscribe to our newsletter to stay up to date on features and releases</Text>
						<Flex w='100%' mt="4"><input type="email" style={{ width: "100%", border: "1px solid rgba(1, 220, 148, 1)", borderRadius: "30px", padding: "10px 20px"}} placeholder="Enter your email" ref={ref1} /></Flex>

						<Flex mt="2" w="100%" justify="center" cursor="pointer" borderRadius="30px" padding="10px 28px" bg="rgba(1, 220, 148, 1)" color="#000" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" fontWeight="bold" align="center" onClick={() => {
							if(ref1.current.value !== "") {
								setLoading(true)
								setTimeout(function() {
									Toast("You have been added to Algorand Foundation newsletter", "success")
									setLoading(false)
									ref1.current.value = ""
								}, 1500)
							}
							else {
								Toast("Kindly enter your email", "error")
							}
						}}>{loading ? <Spinner color="#000" emptyColor="lightgrey" /> : <><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Subscribe</>}</Flex>
					</Flex>
					<Flex flex="1" px={["5", "0"]} mt={["12", "0"]} justify={["space-between", "flex-end"]} pr={["5", "20%"]}>
						<Text mr={["0", "12"]}>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://about-us", "_SELF")}>About us</Text>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://education", "_SELF")}>Education</Text>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://impact", "_SELF")}>Impact</Text>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://ecosystem", "_SELF")}>Ecosystem</Text>
						</Text>
						
						<Text>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://developers", "_SELF")}>Developers</Text>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://governance", "_SELF")}>Governance</Text>
							<Text cursor="pointer" mb="5" onClick={() => window.open("https://community", "_SELF")}>Community</Text>
							<Text cursor="pointer" mb="5" onClick={() => {
								const element = document.getElementById('fq')
								return window.scrollTo({
								top: element ? element.offsetTop - 50 : 0,
								behavior: 'smooth',
								})
							}}>FAQ</Text>
						</Text>
					</Flex>
				</Flex>

				
				<Text borderTop="1px solid rgb(200,200,200)" mx={["5%", "6%"]}></Text>

				<Text py="8" fontSize={["13px", "15px"]} textAlign="center" color="rgb(150,150,150)">&copy; Algorand Foundation. All Rights Reserved. 2023</Text>

				<AdShow />
			</Flex>
		</ChakraProvider>
	)
}

export default App;
